<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* background-color: blue; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@viewport {
  width: device-width ;
  zoom: 1.0;
}
@-ms-viewport {
  width: device-width ;
}
.App-link {
  color: #61dafb;
}
.navBar {
  /* background-color: #231f20; */
  background-color: #287272;
  display: grid;
  grid-template-columns: auto 30% auto;

}
.infoLinks {
  /* display: grid;
  grid-template-columns: auto auto auto; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 10%; */
  /* padding: 2%; */
}
/* #linkedin {
  margin: 1%;
} */
.infoLinks :hover {
  /* fill: #FF8B8B; */
  filter: invert(0.5) sepia(1) hue-rotate(300deg) saturate(2.5)
    brightness(1);
}
#logo {
  /* margin-top: 5%; */
  display: flex;
  align-items: center;
}
#logo img:hover {
  /* background-color: #FF8B8B; */
  filter: invert(0.55) sepia(1.5) hue-rotate(310deg) saturate(2)
    brightness(1.3);
}
#medium {
  margin-top: 2%;
}
.navBar img {
  background-color: #yellow;
  /* border-radius: 2em; */

}
.links  {
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  margin-top: 25%;
  /* align-self: bottom; */
}
.links a:hover {
  background-color: #FF8B8B;
}
.homeOutter {
  padding-top: 10%;
  display: grid;
  grid-template-columns: auto 50% auto;
  /* grid-template-rows: 100% 100%; */

}
/* .homeInner {
  border-color: black;
  border-style: solid;

} */
.homeInner img {
  width: 100%;
  /* justify-content: : */
}
.homeInner span {
  font-size: calc(1em + 2.5vw);
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 40%;
}
.homeInner span:hover {
  color: #FF8B8B;
}
.portfolioPage {
  margin-top: 5%;
  display: grid;
  grid-template-rows: auto auto;
  /* grid-template-columns: 20% auto 20%; */
  grid-row-gap: 7%;
 }
 #description {
   font-family: 'Montserrat', sans-serif;
   font-size: 2em;
   display: flex;
   justify-content: center;
 }
 .developerPage span {
   font-size: 2em;
   font-family: 'Montserrat', sans-serif;
 }
 .portfolioPage img {
   /* height: 2000px; */
   max-width: 100%;
 }
.portfolioPage span {
  font-size: 2em;
  font-family: 'Montserrat', sans-serif;

  /* display: flex;
  justify-content:center; */
}
.center {
  display: flex;
  justify-content: center;
}
.arrows {
  display: flex;
  justify-content: center;
}
.about {
  padding: 10%;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
}
#name {
  font-size: 2em;
}
.resume {
  margin-left: 30%;
}
.img-thumbnail {
  border-radius: 2em;
}
.demoVideo {
  /* padding: 5%; */
  padding-left: 15%;
  padding-right: 15%;
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-column-gap: 15px;
  grid-auto-rows: auto auto;
  grid-row-gap: 5%;
}
.innerDemo {
  display: grid;
  /* grid-column: 2/ 1; */
  grid-template-columns: 20% auto;
  grid-column-gap: 10px;
  grid-template-row: auto auto;
  /* grid-row: 1/ 2; */
}
.btn-outline-dark {
  width: 100%;
  z-index: 2;
}
#outerSpans {
  /* margin-top: 10%; */
  font-weight: bold;
  display: flex;
  justify-content: center;
}
/* .embed-responsive embed-responsive-21by9 {
  display: grid;
  grid-template-rows: 80% auto;
  grid-row-gap: 15px;
} */
.description {
  /* display: grid;
  grid-template-rows: auto auto auto auto; */
/* height: 50%;
vertical-align: text-bottom; */
line-height: 3em;

/* align-content: space-around; */
}
.carousel-slide {
  /* position: absolute; */
  z-index: -1;
  margin: auto;
   width: 50%;
   /* background-color: #231f20; */
   /* padding: 30px; */

}
#designSpan {
  font-weight: bold;
}
.graphicDesign {
  /* display: flex;
  justify-content: center; */
  display: grid;
  grid-template-columns: auto 60% auto;
  /* margin-left: 10%; */
}

/* .carousel-slide img {
  max-height: 70%;
  justify-content: center;
  display: grid;
  grid-template-columns: auto auto auto;
} */
.chickenProcess {
  display: grid;
  grid-template-columns: auto auto;
}
.carousel-item {
  height: 100%;
}
#threeColumn {
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-template-rows: 40% auto 40%; */

}
#magazine {
  display: grid;
  grid-template-columns: auto auto auto;
}
#twoColumn {
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
}
.costumeVideos {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  width: 80%;
  margin-left: 23%;
  margin-top: 7%;
}
.costumeVideos h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: .5em;
}
.wrapper {
  font-family: inherit;
  width: 100%;
  margin: 0 auto;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* position: absolute; */
  /* background-color: blue; */
}
/* video {
  display: block;
  width: 80%;
  height: auto;
} */
.content {
  position: absolute;
  top:45%;
  left:50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 1.5em;
}
.overlay  {
  position: absolute;
  top: 10%;
  left:0;
  bottom: 0;
  right:0;
  height: 75%;
  width: 100%;
  opacity: 0;
  transition: .10s ease;
  background: #287272;
}
.wrapper :hover .overlay {
  opacity: .75;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
